/** 运营联盟 */
<template>
  <div class="union">
    <!-- 联盟介绍 -->
    <section class="union-introduce">
      <div :class="['content', {'flex':$isPc}]">
        <el-image class="introduce-img" :src="require('@/assets/images/union-img.png')" fit="cover"></el-image>
        <div class="introduce-info flex-1">
          <h4 class="introduce-title" v-html="title"></h4>
          <p :class="['introduce-text',{'introduce-text-half':!$isPc},{'loke-all':lookMore}]" v-html="unionInfo"></p>
          <el-button v-if="!$isPc" type="text" :icon="lookMore?'el-icon-arrow-up':'el-icon-arrow-down'"
            class="look-more-down" @click="lookMore=!lookMore">
            {{lookMore?'收起更多':'查看更多'}}
          </el-button>
        </div>
      </div>
    </section>
    <!-- 成员名单 -->
    <section class="union-con content">
      <div class="ipbs-title">
        <h4>成员名单</h4>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="item.label" :name="item.label" v-for="(item,index) in tabs" :key="index">
          <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
            <List :data="unionData" :loading="loading" :total="pageTotal" :resetPage="resetPage" @page="changePage">
              <template v-slot="{item}">
                <div class="union-main">
                  <div class="union-img flex-center">
                    <el-image :src="$base+item.operate_logo" fit="contain"> </el-image>
                  </div>
                  <p class="union-name">{{item.operate_name}}</p>
                </div>
              </template>
            </List>
          </LoadMore>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>
<script>
import { getUnion } from '@/api/index'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
export default {
  name: 'Union',
  components: {
    LoadMore,
    List
  },
  data () {
    return {
      title: `北京经济技术开发区${!this.$isPc ? '<br/>' : ''}知识产权运营服务联盟`,
      activeName: '知识产权创造',
      unionInfo: `为服务北京经济技术开发区建设有国际影响力的科技成果转化承载区，促进北京经济技术开发区知识产权服务业集聚发展，提高知识产权运营服务水平，<strong>北京经济技术开发区科技创新局</strong>于 2021 年初成立“北京经济技术开发区知识产权运营服务联盟”（以下简称“运营联盟”），联盟成员共计 43 家。运营联盟是由知识产权创造、运用、管理、保护、服务领域的企业及服务机构自愿组成的联合组织。运营联盟将依托北京经济技术开发区知识产权运营服务平台，统筹协调知识产权运营服务机构，提供专业咨询、专利挖掘、运营等服务；定期举办企业知识产权专题培训、技术咨询、信息交流等活动；提供信息共享平台，推动联盟成员之间的合作，实现资源共享、优势互补。为进一步提升经开区知识产权创造、运用、保护、管理、服务水平提供重要支撑！`,
      lookMore: false,//查看更多
      tabs: [
        { name: 'one', label: '知识产权创造' },
        { name: 'two', label: '知识产权运用' },
        { name: 'three', label: '知识产权保护' },
        { name: 'four', label: '知识产权管理' },
        { name: 'five', label: '知识产权服务' }
      ],

      unionData: [],
      pageTotal: 0,
      loading: false,
      finished: false,
      page: 1,
      resetPage: false,//分页重置

    }
  },
  mounted () {
    this.onLoadMore()
  },
  methods: {
    handleClick (tab) {
      this.unionData = []
      this.pageTotal = 0
      this.page = 1
      this.resetPage = !this.resetPage
      this.loading = true
      this.finished = false
      this.onLoadMore()
    },
    async getUnion () {
      let filtersData = { operate_type: this.activeName, page: this.page, limit: 15 }

      try {
        let { data, total } = await getUnion(filtersData)
        this.unionData = this.$isPc ? data : [...this.unionData, ...data]
        this.pageTotal = total
        this.loading = false;
      } catch (error) {

      }
    },
    // 切换分页
    changePage (page) {
      this.page = page
      this.onLoadMore()
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.getUnion()
      done && done();
      if (this.unionData.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.page += 1
    },
  },
}
</script>
<style lang="scss" scoped>
.union-img {
  border-radius: 4px;
  box-shadow: 0px 2px 9px 0px rgba(5, 20, 126, 0.31);
}
// 联盟介绍
.union-introduce {
  min-height: 315px;
  background-color: #3030e7;
  padding: 40px 0;
  .introduce-img {
    max-width: 500px;
    height: 100%;
    margin-right: 40px;
  }
  .introduce-info {
    color: #fff;
    .introduce-title {
      font-size: 30px;
      margin-bottom: 30px;
    }
    .introduce-text {
      line-height: 26px;
      overflow: hidden;
      transition: all 0.5s;
      &.introduce-text-half {
        max-height: 160px;
      }
      &.loke-all {
        max-height: 1000px;
      }
    }
  }
}
.union-main {
  width: 100%;
  .union-img {
    height: 120px;
    padding: 10px;
    margin-bottom: 20px;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
  .union-name {
    line-height: 18px;
  }
}
/deep/ .el-tabs {
  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
      .el-tabs__item {
        font-size: 18px;
      }
    }
  }
}
/deep/.list {
  padding: 6px;
  flex-direction: row;
  .item {
    padding: 0;
    width: 19%;
    height: auto;
    border-bottom: none;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  // 联盟介绍
  .union-introduce {
    margin: 0;
    padding: 0;
    .introduce-img {
      margin: 0;
      width: 100%;
      height: 154px;
    }
    .introduce-info {
      padding: 20px 10px 10px;
      .introduce-title {
        font-size: 24px;
        margin-bottom: 10px;
        line-height: 32px;
        text-align: center;
      }
    }
  }
  .union-main {
    .union-img {
      height: 60px;
      margin-bottom: 10px;
    }
    .union-name {
      font-size: 14px;
      line-height: 16px;
    }
  }
  /deep/ .el-tabs {
    .el-tabs__nav-wrap {
      &.is-scrollable {
        padding: 0 38px;
      }
      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        width: 38px;
        line-height: 42px;
        font-size: 24px;
        text-align: center;
      }
      .el-tabs__nav-scroll {
        display: block;
        .el-tabs__item {
          padding: 0 10px;
          font-size: 16px;
        }
      }
    }
  }
  /deep/.list {
    padding: 10px;
    .item {
      width: 48%;
      margin-bottom: 10px;
    }
  }
}
</style>